$container-width: 1170px;
$navigation-width: 229px;

$page-container-height: calc(100vh - 20px - 64px - 20px);

$default-border-radius: 3px;

$wide-width: 1680px;
$large-width: 1440px;
$desktop-width: 1170px;
$tablet-width: 768px;
$mobile-width: 480px;

$mobile-padding: 16px;

// $font-size-root: 16px;
// $input-font-size: 16px;
// $header-height: 64px;
