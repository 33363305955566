$primaryBodyBg: #e5e5e5;
//$primaryNavBg: linear-gradient(180deg, #2d4d70 0%, #14273c 70.55%, #050e1a 100%);
$primaryHeaderBg: linear-gradient(270deg, #2d4d70 11.98%, #213b57 91.82%, #14273c 100%);
$primaryIconBg: #f0f3f6;

$primaryText: #ffffff;
$secondaryText: #a6aebf;
$thirdColorText: #50c5e9;

$primaryBodyGradientBg: linear-gradient(180deg, #14283f 0%, #050e1a 100%);

$primaryAppSectionBg: linear-gradient(270deg, #2d4d70 7.39%, #213b57 33.7%, #14273c 86.52%);

$primaryComponentWrapperBg: #2d4d70;

$primaryLanding: #2af3f3;