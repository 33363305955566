@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';































@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
.Settings_root {
  @media only screen and (min-width: $tablet-width) {
    height: $page-container-height;
  }
}

.tabs {
  display: flex;

  .tab {
    display: block;
    padding: 13px 10px;
    text-transform: uppercase;
    color: $primaryLanding;
    letter-spacing: 0.04em;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    width: 100%;
    transition: 0.25s all ease-in-out;
    border: solid 1px $primaryLanding;
    border-right: none;
    border-left: none;

    @media screen and (min-width: 768px) {
      width: 100px;
    }

    &:not(.router-link-active):hover {
      background: #a5ffff;
      color: $primaryComponentWrapperBg;
      border-color: #a5ffff;
    }

    &.router-link-active {
      color: $primaryComponentWrapperBg;
      background: $primaryLanding;
    }
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left: solid 1px $primaryLanding;
    }

    &:last-child {
      border-right: solid 1px $primaryLanding;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
